import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { USER } from "redux/constants";
import Cookies from "js-cookie";
// Register and Login Screen
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
// Users Shell
import CompanyShell from "views/CompanyShell";

const Routes = (props) => {
  const { isLoggedIn, userRole, updateUser } = props;

  useEffect(() => {
    /**
     * Used to persisit data on refresh
     */
    if (isLoggedIn) {
      const data = JSON.parse(Cookies.get("_W_U_DATA_"));
      const userRole = Number(data.userRole); // 1 = user, 2 = company, 3 = guide
      updateUser({ data, userRole });
    }
  }, [isLoggedIn]);

  const renderView = () => {
    // 1 = user, 2 = company, 3 = guide

    if (isLoggedIn) {
      return <CompanyShell />;
    } else {
      return <Login />;
    }
  };

  return (
    <Switch>
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="/">{renderView()}</Route>
    </Switch>
  );
};

const mapState = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  userRole: state.user.userRole,
});

const mapDispatch = (dispatch) => ({
  updateUser: (payload) => dispatch({ type: USER.UPDATE, payload }),
});

export default connect(mapState, mapDispatch)(Routes);
