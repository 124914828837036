import { userModule } from "./config";
import Cookies from "js-cookie";

export const Queries = {
  getAllUsers: () => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/getAllUsers`, { headers });
  },

  getUserProjects: ({ id }) => {
    const headers = { Authorization: Cookies.get("token") };
    return userModule.get(`/getAllUserProjects/${id}`, { headers });
  },
};
