import { useState, useEffect, memo } from "react";
import { Collapse, Row, Col, Divider, message } from "antd";
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useMutation, Queries, errorHandler } from "apis/config";
import { useSelector } from "react-redux";
import { TiMessages } from "react-icons/ti";
import chatImage from "images/chat.png";
const { Panel } = Collapse;

const MessageList = () => {
  const { userRole, companyAssosiated } = useSelector((state) => state.user.data);
  const [messageList, setMessageList] = useState([]);

  const { mutateAsync, isLoading } = useMutation(Queries.getMessagesForCompany);

  useEffect(() => {
    if (userRole == 2) {
      mutateAsync()
        .then(({ data }) => {
          const mappedData = mapMessageListForCompany(data.data);
          setMessageList([
            {
              id: "messageToSales",
              name: "Admin",
              profile: chatImage,
              createdAt: new Date().toISOString(),
              message: "Click here to send messages to system admin",
            },
            ...mappedData,
          ]);
        })
        .catch((err) => message.error(errorHandler(err)));
    }
  }, []);
  const forUser = companyAssosiated == "" ? forIndividualUser : forCompanyUser;
  const messageItems = userRole == "1" ? forUser : messageList;
  return (
    <StyledWrapper>
      <Collapse style={{ width: "100%" }}>
        <Panel
          key="1"
          showArrow={false}
          header={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "0px 8px",
              }}
            >
              <h3 style={{ fontWeight: 600, color: "#fff", marginBottom: 0 }}>
                Messages
              </h3>
              <TiMessages style={{ color: "#fff", fontSize: 22 }} />
            </div>
          }
          style={{ backgroundColor: "#135bed", borderRadius: "4px 4px 0 0" }}
        >
          {messageItems.map((item, index) => (
            <Link key={item.id} to={`/message/${item.id}`}>
              <Row gutter={[12, 0]} align="middle" className="message-row">
                <Col span={4}>
                  <img className="profile-image" src={item.profile} alt={item.name} />
                </Col>

                <Col span={20}>
                  <section>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="name">{item.name}</div>
                      <div className="time">
                        {moment(item.createdAt).format("DD MMM")}
                      </div>
                    </div>

                    <div className="message">{item.message}</div>
                  </section>
                </Col>

                {index + 1 < messageItems.length ? (
                  <Col span={24}>
                    <Divider style={{ margin: "12px 0" }} />
                  </Col>
                ) : null}
              </Row>
            </Link>
          ))}
        </Panel>
      </Collapse>
    </StyledWrapper>
  );
};

export default memo(MessageList);

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 48px;
  width: 300px;
  z-index: 10000;

  .message-row {
    cursor: pointer;
    color: #000;
  }

  .profile-image {
    object-fit: cover;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .name {
    font-weight: 600;
  }
  .time {
    color: #00000099;
  }

  .message {
    color: #443535;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const forIndividualUser = [
  {
    id: "messageToAdmin",
    name: "Admin",
    profile: chatImage,
    createdAt: new Date().toISOString(),
    message: "Click here to send messages to system admin",
  },
];

const forCompanyUser = [
  {
    id: "messageToCompany",
    name: "Company",
    profile: chatImage,
    createdAt: new Date().toISOString(),
    message: "Click here to send messages to your company",
  },
  {
    id: "messageToAdmin",
    name: "Admin",
    profile: chatImage,
    createdAt: new Date().toISOString(),
    message: "Click here to send messages to system admin",
  },
];

const mapMessageListForCompany = (messageList) => {
  return messageList.map((item) => {
    const userId = item.userId;
    return {
      id: userId._id,
      name: userId.fullName,
      profile: userId.pictureURL,
      createdAt: item.messages[0].createdAt,
      message: item.messages[0].message,
    };
  });
};
