export const USER = {
  LOGIN: "LOGIN_AS_ADMIN",
  LOGOUT: "LOGOUT_ADMIN",
  UPDATE: "UPDATE_ADMIN_INFO",
};

export const INSTRUCTORS = {
  STORE: "STORE_ALL_INSTRUCTORS",
  INSERT: "INSERT_INSTRUCTOR",
};

export const ALL_USERS = {
  STORE: "STORE_ALL_USERS",
  INSERT: "INSERT_USER",
};
