import { INSTRUCTORS } from "../constants";

const initialState = { allInstructors: [], selectOptions: [] };

const instructorReducer = (state = initialState, action) => {
  switch (action.type) {
    case INSTRUCTORS.STORE: {
      const { payload } = action;
      const selectOptions = payload.map((item) => ({
        label: item.fullName,
        value: item._id,
      }));
      return { ...state, allInstructors: payload, selectOptions };
    }

    case INSTRUCTORS.INSERT: {
      const { payload } = action;
      const selectOptions = [
        { label: payload.fullName, value: payload._id },
        ...state.selectOptions,
      ];
      // console.log("Select Options: \n", selectOptions);
      return {
        ...state,
        allInstructors: [payload, ...state.allInstructors],
        selectOptions,
      };
    }

    default:
      return state;
  }
};

export default instructorReducer;
