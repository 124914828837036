import { useState, useEffect } from "react";
import { useMutation, errorHandler, Queries } from "apis/config";
import { Route, Switch } from "react-router-dom";
import { Layout, message } from "antd";
import MessageList from "components/message";
import HeaderWrapper from "components/header/headerWrapper";
import FooterWrapper from "components/footer/footerWrapper";
import SidebarWrapper from "components/sidebar/sidebarWrapper";
import { useDispatch } from "react-redux";
import { INSTRUCTORS, ALL_USERS } from "redux/constants";
import Dashboard from "./Dashboard";
import Users from "./Users";
import Projects from "./Projects";

const { Content } = Layout;

const CompanyShell = () => {
  const dispatch = useDispatch();
  const [collapsed, toggleCollapsed] = useState(true);

  return (
    <div>
      <Layout>
        <div style={{ maxHeight: "100vh" }}>
          <SidebarWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        </div>

        <Layout>
          <HeaderWrapper collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <div
            style={{
              maxHeight: "calc(100vh - 49px)",
              overflow: "auto",
              backgroundColor: "#F4F6FB",
            }}
          >
            <section style={{ minHeight: "calc(100vh - 115px)", overflow: "auto" }}>
              <Content style={{ padding: 44, backgroundColor: "#F4F6FB" }}>
                <Switch>
                  {/* <Route exact path="/" component={Dashboard} /> */}
                  <Route exact path="/">
                    <Users />
                  </Route>
                  <Route exact path="/projects/:id">
                    <Projects />
                  </Route>
                </Switch>
              </Content>
            </section>
            <FooterWrapper />
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default CompanyShell;
