import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { errorHandler } from "helpers/errorHandler";
import { Queries } from "./queries";
import { Mutations } from "./mutations";

const userModule = axios.create({
  baseURL: process.env.REACT_APP_AUTH_AMDIN_MODULES + "/api/admin",
  // baseURL: `http://192.168.88.8:4003/api/admin`,
});

export { useMutation, useQuery, errorHandler, userModule, Queries, Mutations };
