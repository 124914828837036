import React, { useState, useEffect } from "react";
import { InputWrapper } from "../components/input";
import { ArrowRightOutlined } from "@ant-design/icons";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, errorHandler, Mutations } from "apis/config";
import { ButtonWrapper } from "components/buttons";
import { message, Form } from "antd";
import { StyledContent } from "./Login";
import BannerImage from "images/loginTopImage.svg";
import { loginAction } from "redux/actions";
import { useDispatch } from "react-redux";
import OTPInputWrapper from "components/buttons/otpInput";
import moment from "moment";

const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const [state, setState] = useState({
    code: "",
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  /**
   * Muatation and handler for forgot password
   */
  const { isLoading: resendCodeLoading, mutateAsync: resendCodeMutation } = useMutation(
    Mutations.forgotPassword
  );

  const handleResendCode = async () => {
    try {
      await resendCodeMutation({ email: location?.state?.email });
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  /**
   * Muatation and handler for reset password
   */
  const { isLoading, mutateAsync } = useMutation(Mutations.resetPassword);

  const handleResetPassword = async () => {
    const deviceId = Math.floor(100000 + Math.random() * 900000).toString();
    if (state.newPassword === state.confirmPassword) {
      try {
        const { data } = await mutateAsync({
          email: location?.state?.email,
          code: state.code,
          newPassword: state.newPassword,
          deviceId,
          lastLogin: moment().format("DD-MMM-YYYY HH:mm A"),
        });
        dispatch(loginAction({ ...data.data, deviceId }));
        history.push("/");
      } catch (err) {
        message.error(errorHandler(err));
      }
    } else {
      message.error("Password doesn't match");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (location?.state?.email) {
      form.setFieldsValue({ Email: location.state.email });
      setState({ ...state, email: location.state.email });
    }

    return () => form.resetFields();
  }, [location.state]);

  return (
    <StyledContent>
      <div>
        <Link to="/forgot-password">
          <div className="back-icon">
            <BiLeftArrowAlt fontSize={20} fill="#000" />
          </div>
        </Link>

        <div className="card-wrapper">
          <div className="card-banner">
            <div style={{ alignSelf: "flex-start", margin: "24px 0 0 32px" }}>
              <div className="banner-wrapper">
                <div style={{ fontWeight: 600, fontSize: 16 }}>
                  Didn't receive the email yet?
                </div>
                <p style={{ fontWeight: 500, fontSize: 13, marginTop: 6 }}>
                  Please check your spam folder, of the email.
                </p>
              </div>
            </div>
            <div>
              <img src={BannerImage} alt="Logo" width={220} />
            </div>
          </div>

          <div style={{ padding: "48px 32px 32px 32px" }}>
            <Form form={form} onFinish={handleResetPassword} validateTrigger="onFinish">
              <h4 className="form-label">Email</h4>
              <Form.Item name="Email" rules={[{ type: "email", required: true }]}>
                <InputWrapper value={state.email} placeholder="abc@xyz.com" disabled />
              </Form.Item>

              <h4 className="form-label">Code</h4>
              <Form.Item name="Code" rules={[{ type: "string", min: 5, required: true }]}>
                <OTPInputWrapper
                  name="code"
                  value={state.code}
                  OTPLength={6}
                  onChange={handleChange}
                  onResend={handleResendCode}
                  loading={resendCodeLoading}
                />
              </Form.Item>

              <h4 className="form-label">New Password</h4>
              <Form.Item
                name="New Password"
                rules={[{ type: "string", min: 8, required: true }]}
              >
                <InputWrapper.Password
                  name="newPassword"
                  value={state.newPassword}
                  placeholder="New password"
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </Form.Item>

              <h4 className="form-label">Confirm Password</h4>
              <Form.Item
                name="Confirm Password"
                rules={[{ type: "string", min: 8, required: true }]}
              >
                <InputWrapper.Password
                  name="confirmPassword"
                  value={state.confirmPassword}
                  placeholder="Confirm password"
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </Form.Item>

              <ButtonWrapper
                style={{ marginTop: 16, height: 40 }}
                loading={isLoading}
                htmlType="submit"
              >
                Confirm
                {!isLoading ? <ArrowRightOutlined style={{ marginLeft: 8 }} /> : null}
              </ButtonWrapper>
            </Form>
          </div>
        </div>
      </div>
    </StyledContent>
  );
};

export default ResetPassword;
