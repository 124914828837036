import React from "react";
import { DatePicker } from "antd";
import styled from "styled-components";

const StyledCalendar = styled(DatePicker)``;

const DatePickerWrapper = (props) => {
  return (
    <StyledCalendar
      {...props}
      style={{
        width: "100%",
        borderRadius: 6,
        height: 40,
        borderColor: "#d1d1d1",
      }}
      format="DD-MMM-YYYY"
      allowClear={false}
    />
  );
};
export default DatePickerWrapper;

// cb funtion recieverd from props to get the selected date
// function onChange(date, dateString) {
//   console.log(date, dateString);
// }
