import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import UserDetailModal from "components/modals/userDetailModal";
import { MdOutlineLocationOff, MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip, message } from "antd";
import { Queries, useMutation, errorHandler } from "apis/config";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const Projects = (props) => {
  const { loading } = props;
  const params = useParams();

  console.log("params", params);
  const history = useHistory();
  const [allUsers, setAllUsers] = useState([]);
  const [tableData, setTableData] = useState([]);

  // const { allUsers } = useSelector((state) => state.users);
  const [userModal, toggleUserModal] = useState(false);
  const user = useRef();

  const { mutateAsync: getUserProjects, isLoading: getUsersLoading } = useMutation(
    Queries.getUserProjects
  );

  useEffect(() => {
    getUserProjects({ id: params.id })
      .then(({ data }) => {
        setAllUsers(data.data);
      })
      .catch((e) => message.error(errorHandler(e)));
  }, [params.id]);

  const handleSearch = (val) => {
    const filteredOptions = allUsers.filter((option) => {
      return (
        option.firstName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.lastName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  useEffect(() => {
    setTableData(allUsers);
  }, [allUsers]);

  return (
    <>
      <UserDetailModal
        title="User details"
        visible={userModal}
        onCancel={() => toggleUserModal(false)}
        data={user.current}
      />
      <div>
        <ContentHeader
          title="Projects"
          showSearch={true}
          onSearch={(e) => {
            handleSearch(e);
          }}
        />
        <TableWrapper
          data={tableData}
          showPagination={true}
          totalCount={tableData?.length}
          loading={getUsersLoading}
          columns={[
            {
              Header: "Project Name",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "left", width: "100%" }}>{original.title}</div>
                );
              },
              sortable: false,
            },
            {
              Header: "Created At",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "left", width: "100%" }}>
                    {moment(original.createdAt).format("DD-MM-YYYY")}
                  </div>
                );
              },
              sortable: false,
            },

            {
              Header: "Idea Validatoin Report",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    disabled={
                      original.AIPersonalities == "" || original.analysis == ""
                        ? true
                        : false
                    }
                    onClick={() => history.push("/projects/12345678")}
                  >
                    View
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Market Research Report",
              Cell: ({ original }) => {
                return (
                  <TableButton onClick={() => history.push("/projects/12345678")}>
                    View
                  </TableButton>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </>
  );
};

export default Projects;
