import { combineReducers } from "redux";
import userReducer from "./userReducer";
import instructorReducer from "./instructorReducer";
import allUsersReducer from "./allUsersReducer";

export default combineReducers({
  user: userReducer,
  instructors: instructorReducer,
  users: allUsersReducer,
});
