import React, { useState, useEffect } from "react";
import { Menu, message } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Icon, { UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { BiDownload } from "react-icons/bi";
import { MdVideoLibrary, MdDashboard } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";

const SidebarContent = ({ collapsed }) => {
  const location = useLocation();

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={collapsed}
      selectedKeys={location.pathname}
    >
      {/* <Menu.Item key="/" icon={<Icon component={MdDashboard} />}>
        <Link to="/" style={{ color: "#fff" }}>
          Dashboard
        </Link>
      </Menu.Item> */}

      <Menu.Item key="/" icon={<Icon component={UserOutlined} />}>
        <Link to="/" style={{ color: "#fff" }}>
          Users
        </Link>
      </Menu.Item>
    </Menu>
  );
};
export default SidebarContent;
