import { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import DashboardCard from "components/cards/dashboardCard";
import RangePickerWrapper from "components/input/rangePicker";
import AreaPlot from "components/charts/areaPlot";
import ColumnPlot from "components/charts/columnPlot";
import DonutPlot from "components/charts/donutPlot";
import ContentHeader from "components/header/contentHeader";
import { useMutation, errorHandler, Queries } from "apis/config";
import moment from "moment";

const Dashboard = () => {
  const [companyUsers, setCompanyUsers] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const { mutateAsync: getCompanyUsers, isLoading } = useMutation(
    Queries.getCompanyUsers
  );
  const { mutateAsync: getAllInstructors, isLoading: loading2 } = useMutation(
    Queries.getAllInstructors
  );

  useEffect(() => {
    /**
     * Get all users
     */
    getCompanyUsers()
      .then(({ data }) => {
        const mappedData = mapData(data.data);
        setCompanyUsers(mappedData);
      })
      .catch((err) => message.error(errorHandler(err)));

    /**
     * Get all Instructors
     */
    getAllInstructors()
      .then(({ data }) => {
        const mappedData = mapData(data.data);
        setInstructors(mappedData);
      })
      .catch((err) => message.error(errorHandler(err)));
  }, []);

  return (
    <div>
      <ContentHeader title="Dashboard" />

      <DashboardCard />

      <div style={{ margin: "32px 0" }}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <ColumnPlot
              heading="Users"
              xField="month"
              yField="value"
              color={"#F46A35"}
              data={companyUsers}
            />
          </Col>

          <Col span={12}>
            <ColumnPlot
              heading="Instructors"
              xField="month"
              yField="value"
              color={"#F46A35"}
              data={instructors}
            />
          </Col>

          {/* <Col span={12}>
              <ColumnPlot
                heading="Total transaction fee."
                columnBackgroundColor="#f3f3f3"
                columnBackgroundRadius={[20, 20, 20, 20]}
                columnStyleRadius={[20, 20, 20, 20]}
                isGroup={false}
                xField="month"
                yField="value"
                maxColumnWidth={14}
                apiEndpoint="https://gw.alipayobjects.com/os/antfincdn/8elHX%26irfq/stack-column-data.json"
                color={"#F46A35"}
                dataSheet={Total_transaction_fee}
                meta={{ value: { formatter: (val) => `${val}K`, type: "linear" } }}
              />
            </Col> */}

          {/* <Col span={14}>
            <AreaPlot
              heading="Total amount of transaction between all users."
              color="#00CFE8"
              dataSheet={Total_amount_of_transaction_between_all_users}
              meta={{ value: { formatter: (val) => `${val}K` } }}
            />
          </Col>

          <Col span={10}>
            <ColumnPlot
              heading="Total number of transaction between all users."
              columnBackgroundColor="#f3f3f3"
              columnBackgroundRadius={[20, 20, 20, 20]}
              columnStyleRadius={[20, 20, 20, 20]}
              isGroup={false}
              xField="month"
              yField="value"
              maxColumnWidth={14}
              apiEndpoint="https://gw.alipayobjects.com/os/antfincdn/8elHX%26irfq/stack-column-data.json"
              color={"#6F42C1"}
              dataSheet={Total_transaction_fee}
              meta={{ type: "linear" }}
            />
          </Col>

          <Col span={12}>
            <DonutPlot heading="Total number of invoices generated vs paid" />
          </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;

const mapData = (data) => {
  const dataObject = data.reduce((initial, user) => {
    const createdAt = moment(user.createdAt).format("YYYY-MM-DD");

    if (initial[createdAt]) {
      initial[createdAt] += 1;
    } else {
      initial[createdAt] = 1;
    }

    return initial;
  }, {});

  let graphData = [];

  for (let key in dataObject) {
    graphData.push({
      month: key,
      value: dataObject[key],
    });
  }
  graphData.sort((a, b) => (a.month > b.month ? 1 : -1));
  return graphData;
};
