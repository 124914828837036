import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import TableWrapper from "components/table/reactTable";
import ContentHeader from "components/header/contentHeader";
import { TableButton } from "components/buttons";
import UserDetailModal from "components/modals/userDetailModal";
import { MdOutlineLocationOff, MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip, message } from "antd";
import { Queries, useMutation, errorHandler } from "apis/config";

const Users = (props) => {
  const { loading } = props;
  const history = useHistory();
  const [allUsers, setAllUsers] = useState([]);
  const [tableData, setTableData] = useState([]);

  // const { allUsers } = useSelector((state) => state.users);
  const [userModal, toggleUserModal] = useState(false);
  const user = useRef();

  const { mutateAsync: getAllUsers, isLoading: getUsersLoading } = useMutation(
    Queries.getAllUsers
  );

  useEffect(() => {
    getAllUsers()
      .then(({ data }) => {
        setAllUsers(data.data);
      })
      .catch((e) => message.error(errorHandler(e)));
  }, []);

  const handleSearch = (val) => {
    const filteredOptions = allUsers.filter((option) => {
      console.log("optoi, n", option);
      return (
        option.firstName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.lastName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        option.email.toLowerCase().includes(val.target.value.toLowerCase())
      );
    });
    setTableData(filteredOptions);
  };

  useEffect(() => {
    setTableData(allUsers);
  }, [allUsers]);

  return (
    <>
      <UserDetailModal
        title="User details"
        visible={userModal}
        onCancel={() => toggleUserModal(false)}
        data={user.current}
      />
      <div>
        <ContentHeader
          title="Users"
          showSearch={true}
          onSearch={(e) => {
            handleSearch(e);
          }}
        />
        <TableWrapper
          data={tableData}
          showPagination={true}
          totalCount={tableData?.length}
          loading={getUsersLoading}
          columns={[
            {
              Header: "Full Name",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "left", width: "100%" }}>
                    {original.firstName} {original.lastName}
                  </div>
                );
              },
              sortable: false,
            },
            {
              Header: "Email",
              Cell: ({ original }) => {
                return (
                  <div style={{ textAlign: "left", width: "100%" }}>{original.email}</div>
                );
              },
              sortable: false,
            },
            // {
            //   Header: "Phone",
            //   Cell: ({ original }) => {
            //     return (
            //       <div style={{ textAlign: "center", width: "100%" }}>
            //         {original.phoneNumber}
            //       </div>
            //     );
            //   },
            //   sortable: false,
            // },
            {
              Header: "Details",
              Cell: ({ original }) => {
                return (
                  <TableButton
                    onClick={() => {
                      user.current = original;
                      toggleUserModal(true);
                    }}
                  >
                    View
                  </TableButton>
                );
              },
              sortable: false,
            },
            {
              Header: "Projects",
              Cell: ({ original }) => {
                console.log("original", original);
                return (
                  <TableButton onClick={() => history.push(`/projects/${original._id}`)}>
                    View
                  </TableButton>
                );
              },
              sortable: false,
            },
          ]}
        />
      </div>
    </>
  );
};

export default Users;
