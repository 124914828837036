import React, { useState } from "react";
import { InputWrapper } from "components/input";
import BannerImage from "images/loginTopImage.svg";
import { Form, message, Row, Col } from "antd";
import Icon, { ArrowRightOutlined, LoadingOutlined, LockFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction } from "redux/actions";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonWrapper } from "components/buttons";
import { useMutation, errorHandler, Mutations } from "apis/config";
import validatorLogo from "images/validatorLogo.png";
import moment from "moment";

export const StyledContent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  * p {
    margin: 0;
  }

  .card-wrapper {
    position: relative;
    width: 456px;
    background-color: #f4f6fb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }

  .card-banner {
    display: flex;
    align-items: end;
    justify-content: space-between;
    background-color: #e0e6f6;
    height: 136px;
    width: -webkit-fill-available;
    width: -moz-available;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .banner-icon {
    font-size: 48px;
    /* background-color: #fff; */
    /* height: 72px;
    width: 72px; */
    /* line-height: 82px; */
    /* border-radius: 50%; */
    position: absolute;
    top: 96px;
    left: 32px;
    /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.09), -2px -2px 4px rgba(0, 0, 0, 0.09),
      2px -2px 4px rgba(0, 0, 0, 0.09), -2px 2px 4px rgba(0, 0, 0, 0.09); */
  }

  .form-label {
    text-align: left;
    margin-bottom: 8px;
    font-size: 16px;
  }

  .forgot-password a {
    color: #000;
  }
`;

const LoginNew = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({ email: "", password: "" });

  const { isLoading, mutateAsync } = useMutation(Mutations.login);

  const handleLogin = async () => {
    const deviceId = Math.floor(100000 + Math.random() * 900000).toString();
    try {
      const { data } = await mutateAsync({
        email: state.email,
        password: state.password,
        deviceId,
      });
      dispatch(loginAction({ ...data.data, deviceId }));
      // history.push("/");
    } catch (err) {
      message.error(errorHandler(err));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <StyledContent>
      <div className="card-wrapper">
        <div className="card-banner">
          <div style={{ alignSelf: "flex-start", margin: "24px 0 0 32px" }}>
            <div className="banner-wrapper">
              <div style={{ fontWeight: 600, fontSize: 18 }}>Welcome Back</div>
              <p style={{ color: "#012169", fontWeight: 500 }}>Sign in to continue</p>
            </div>
          </div>
          <div>
            <img src={BannerImage} alt="Logo" width={240} />
          </div>
        </div>

        <div>
          <img alt="app icon" src={validatorLogo} width={150} className="banner-icon" />
        </div>

        <div style={{ margin: "48px 32px 32px 32px" }}>
          <Form onFinish={handleLogin} validateTrigger="onFinish">
            <h4 className="form-label">Email</h4>
            <Form.Item name="Email" rules={[{ type: "email", required: true }]}>
              <InputWrapper
                name="email"
                value={state.email}
                placeholder="abc@xyz.com"
                onChange={handleChange}
              />
            </Form.Item>

            <h4 className="form-label">Password</h4>
            <Form.Item
              name="Password"
              rules={[{ type: "string", required: true, min: 3 }]}
            >
              <InputWrapper.Password
                name="password"
                value={state.password}
                onChange={handleChange}
                placeholder="Password"
              />
            </Form.Item>

            {/* <Row align="middle" justify="center">
              <Col>
                <div className="forgot-password">
                  <Link to="/forgot-password">
                    <LockFilled style={{ color: "#C7C8CA" }} /> Forgot Password
                  </Link>
                </div>
              </Col>
            </Row> */}

            <ButtonWrapper
              style={{ marginTop: 24, height: 40 }}
              size="large"
              htmlType="submit"
              disabled={isLoading}
            >
              Continue
              {!isLoading ? (
                <ArrowRightOutlined style={{ marginLeft: 8 }} />
              ) : (
                <LoadingOutlined style={{ marginLeft: 8 }} />
              )}
            </ButtonWrapper>
          </Form>

          {/* <div style={{ marginTop: 24, textAlign: "center" }}>
            Don't have an account.{" "}
            <Link to="/register" style={{ color: "#000", fontWeight: 600 }}>
              Register
            </Link>
          </div> */}
        </div>
      </div>
    </StyledContent>
  );
};

export default LoginNew;
